import { BrowserModule } from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { OAuthModule } from 'angular-oauth2-oidc';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ADMIN_URL_PROVIDER, BASE_URL_PROVIDER} from './app-provider';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AuthenticationInterceptor } from './core/auth/authentication.interceptor';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpProgressInterceptor } from './modules/file-uploads/interceptors/http-progress.interceptor';
import { HttpErrorInterceptor } from './core/errors/http-error.interceptor';
import { rollbarFactory, RollbarService } from './core/services/rollbar.service';
import { RollbarErrorHandler } from './core/errors/rollbar-error-handler';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [
        AppComponent
    ], imports: [CommonModule,
        BrowserModule,
        OAuthModule.forRoot(),
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production && environment.serviceWorkerEnabled }),
        BrowserAnimationsModule], providers: [
        BASE_URL_PROVIDER,
        ADMIN_URL_PROVIDER,
        { provide: RollbarService, useFactory: rollbarFactory },
        { provide: ErrorHandler, useClass: RollbarErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpProgressInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
